import React from 'react';
import { graphql } from 'gatsby';
import { Container } from 'react-bootstrap';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import HeroImage from '../components/HeroImage';
import ProductListContainer from '../components/ProductListContainer';

export const query = graphql`
  query {
    backgroundImg: file(relativePath: { eq: "default-background.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    products: allContentfulProduct {
      edges {
        node {
          contentful_id
          name
          slug
          price
          category {
            name
          }
          image {
            fixed(width: 286, height: 286) {
              ...GatsbyContentfulFixed
            }
            fluid {
              src
            }
          }
        }
      }
    }
  }
`;

const producten = ({ data }) => {
  return (
    <Layout pageInfo={{ pageName: 'producten' }}>
      <SEO title="Producten" />
      <HeroImage img={data.backgroundImg.childImageSharp.fluid}>
        <h1 className="text-white">Producten</h1>
      </HeroImage>
      <Container>
        <ProductListContainer products={data.products} />
      </Container>
    </Layout>
  );
};

export default producten;
