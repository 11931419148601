import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useStaticQuery, graphql } from 'gatsby';

export const getCategories = graphql`
  {
    allContentfulCategory {
      edges {
        node {
          name
        }
      }
    }
  }
`;

function CategoriesFilter(props) {
  const categories = useStaticQuery(getCategories).allContentfulCategory.edges;
  const [category, setCategory] = useState('all');

  const handleItems = (category) => {
    setCategory(category);
    props.handleCategory(category);
  };

  return (
    <div className="d-flex justify-content-center mt-3 mb-3">
      <Button
        variant={category === 'all' ? 'success' : 'outline-success'}
        className="mr-2"
        onClick={() => {
          handleItems('all');
        }}
      >
        Alle categorieën
      </Button>
      {categories.map((cat, index) => {
        return (
          <Button
            variant={category === cat.node.name ? 'success' : 'outline-success'}
            className="mr-2"
            key={index}
            onClick={() => {
              handleItems(cat.node.name);
            }}
          >
            {cat.node.name}
          </Button>
        );
      })}
    </div>
  );
}

export default CategoriesFilter;
