import React, { useState } from 'react';

import ProductList from './products/ProductList';
import CategoriesFilter from './CategoriesFilter';

function ProductListContainer({ products }) {
  const [category, setCategory] = useState('all');

  const handleCategory = (category) => {
    setCategory(category);
  };

  return (
    <>
      <CategoriesFilter handleCategory={handleCategory} />
      <ProductList products={products.edges} category={category} />
    </>
  );
}

export default ProductListContainer;
